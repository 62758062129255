import { Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

const getSearchKey = apiKey => {
  if (apiKey === 'posSizeSku|menuSizeSku') return 'sizeSku';
  if (apiKey === 'posSize|menuSize') return 'sizeName';
  return apiKey;
};

const FreeTextFilter = ({
  column,
  requestState,
  setRequestState,
  hasFocus,
  setElementWithFocus,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [touched, setTouched] = useState(false);
  const typingDelay = 1000;
  const searchKey = getSearchKey(column.apiKey);
  const inputRef = useRef(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchValue !== '' || touched) {
        setTouched(true);
        setRequestState({
          ...requestState,
          filterQuery: {
            ...requestState.filterQuery,
            [searchKey]: searchValue,
          },
          orders: [],
          isLoading: true,
        });
        setElementWithFocus(column.apiKey);
      }
    }, typingDelay);
    return () => clearTimeout(timeOutId);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasFocus && !requestState.isLoading) inputRef.current.focus();
  }, [hasFocus, requestState.isLoading]);

  if (column.searchable) {
    return (
      <Input
        ref={inputRef}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        isDisabled={requestState.isLoading}
        borderColor="brand.black"
        placeholder="search..."
        minWidth="90px"
        padding="0 12px"
      />
    );
  }
};

export default FreeTextFilter;
