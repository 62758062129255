import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const SuccessModal = ({ selectedOrders, selectedPrinter, onClose }) => {
  return (
    <ModalContent>
      <ModalHeader></ModalHeader>
      <ModalCloseButton />
      <ModalBody textAlign="center">
        <CheckCircleIcon color="brand.primary" width="40px" height="40px" />
        <Text
          marginTop="12px"
          fontWeight="700"
          fontSize="22px"
          lineHeight="33px"
        >
          Success!
        </Text>
        <Text
          marginTop="8px"
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
        >
          {`${selectedOrders.size} order${
            selectedOrders.size > 1 ? 's' : ''
          } sent to ${selectedPrinter}`}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          backgroundColor="brand.primary"
          color="brand.white"
          onClick={onClose}
          width="100%"
        >
          Go back to dashboard
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default SuccessModal;
