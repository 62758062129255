import { Badge, Td } from '@chakra-ui/react';
import printStatusOptions from '../enums/printStatusOptions';

const PrintStatus = ({ status }) => {
  if (status === 6) {
    return (
      <Td>
        <Badge
          padding="4px 8px"
          borderRadius="10px"
          textTransform="none"
          background="brand.error"
          color="brand.white"
          fontSize="12px"
          lineHeight="18px"
          fontWeight="700"
        >
          Error
        </Badge>
      </Td>
    );
  }
  return <Td>{printStatusOptions[status] || printStatusOptions.empty}</Td>;
};
export default PrintStatus;
