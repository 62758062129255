import { Td } from '@chakra-ui/react';
import LinkBadge from './LinkBadge';

const ShipmentId = ({ id }) => (
  <Td>
    <LinkBadge href={`https://www.menumaker.co/#/Shipments/${id}/`}>
      {id}
    </LinkBadge>
  </Td>
);

export default ShipmentId;
