import { WarningIcon } from '@chakra-ui/icons';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const ErrorModal = ({ error, onClose }) => {
  return (
    <ModalContent>
      <ModalHeader></ModalHeader>
      <ModalCloseButton />
      <ModalBody textAlign="center">
        <WarningIcon color="brand.error" width="40px" height="40px" />
        <Text
          marginTop="12px"
          fontWeight="700"
          fontSize="22px"
          lineHeight="33px"
        >
          Error!
        </Text>
        <Text
          textAlign="justify"
          whiteSpace="pre-line"
          marginTop="8px"
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
        >
          {error}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          backgroundColor="brand.primary"
          color="brand.white"
          onClick={onClose}
          width="100%"
        >
          Go back to dashboard
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default ErrorModal;
