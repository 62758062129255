import React from 'react';

export default function HybleLogo() {
  return (
    <svg
      width="57"
      height="24"
      viewBox="0 0 57 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13255_46566)">
        <path
          d="M26.0886 11.2282V10.3149C26.0745 10.4636 26.0674 10.6123 26.0674 10.768C26.0674 10.9238 26.0745 11.0795 26.0886 11.2282Z"
          fill="#0B2429"
        />
        <path
          d="M35.4833 7.2354C34.9523 6.23009 34.2373 5.45133 33.324 4.9062C32.4107 4.36106 31.3983 4.09204 30.2727 4.09204C29.3311 4.09204 28.4957 4.27611 27.7594 4.65133C27.0302 5.02655 26.4638 5.51505 26.0745 6.11682V2.1239C26.0745 0.969914 25.1399 0.0424805 23.993 0.0424805C22.8461 0.0424805 21.9116 0.976994 21.9116 2.1239V15.1788C21.9116 16.3327 22.8461 17.2602 23.993 17.2602C25.0408 17.2602 25.8975 16.4885 26.0461 15.4832L26.0745 15.4549C26.478 16.0637 27.0444 16.5451 27.7594 16.9133C28.4745 17.2814 29.3098 17.4584 30.2727 17.4584C31.3913 17.4584 32.4107 17.1823 33.324 16.6301C34.2302 16.0779 34.9523 15.292 35.4833 14.2796C36.0143 13.2602 36.2762 12.085 36.2762 10.754C36.2762 9.42301 36.0143 8.24779 35.4833 7.24248V7.2354ZM30.6833 13.4938C30.209 13.8053 29.6426 13.9752 29.0762 13.9823C28.2267 13.9894 27.5116 13.7062 26.924 13.1186C26.4355 12.6372 26.1594 12 26.0815 11.2212C26.0674 11.0726 26.0603 10.9239 26.0603 10.7611C26.0603 10.5982 26.0674 10.4496 26.0815 10.308C26.1594 9.52921 26.4355 8.89912 26.924 8.41062C27.4975 7.83717 28.2054 7.5469 29.0337 7.5469C29.8621 7.5469 30.5913 7.83009 31.1647 8.39646C31.7452 8.96283 32.0284 9.74159 32.0284 10.7327C32.0284 11.9646 31.5824 12.8779 30.6833 13.4796V13.4938Z"
          fill="white"
        />
        <path
          d="M41.968 2.1239V15.1788C41.968 16.3327 41.0335 17.2602 39.8866 17.2602C38.7326 17.2602 37.8052 16.3257 37.8052 15.1788V2.1239C37.8052 0.969914 38.7397 0.0424805 39.8866 0.0424805C41.0406 0.0424805 41.968 0.976994 41.968 2.1239Z"
          fill="white"
        />
        <path
          d="M18.5912 5.86195C17.5929 5.86195 16.7858 6.64071 16.7858 7.60354V11.8442C16.7858 13.0903 15.7239 14.1097 14.4142 14.1097C13.1044 14.1097 12.0425 13.0903 12.0425 11.8442C12.0425 11.8018 12.0425 11.7593 12.0425 11.7097L11.9858 11.0018C11.9504 7.86549 9.26726 5.31681 6.00354 5.31681C5.18938 5.31681 4.37522 5.47965 3.63186 5.79115V1.74159C3.61062 0.778761 2.80354 0 1.80531 0C0.80708 0 0 0.778761 0 1.74159V10.9593C0 10.9593 0 11.023 0 11.0584V15.2991C0 16.2619 0.80708 17.0407 1.80531 17.0407C2.80354 17.0407 3.61062 16.2619 3.61062 15.2991V11.0584C3.61062 9.81239 4.67257 8.79292 5.9823 8.79292C7.29204 8.79292 8.35398 9.80531 8.35398 11.0584C8.35398 11.1009 8.35398 11.1504 8.35398 11.1929L8.41062 11.9009C8.44602 15.0372 11.1292 17.5858 14.3929 17.5858C15.2212 17.5858 16.0142 17.423 16.7646 17.1115V18.2442C16.7646 19.4903 15.7027 20.5097 14.3929 20.5097C13.3947 20.5097 12.5876 21.2885 12.5876 22.2513C12.5876 23.2142 13.3947 23.9929 14.3929 23.9929C17.692 23.9929 20.3823 21.4159 20.3823 18.2442V7.60354C20.3823 6.64071 19.5752 5.86195 18.577 5.86195H18.5912Z"
          fill="url(#paint0_linear_13255_46566)"
        />
        <path
          d="M56.1276 8.90595C55.6108 7.01569 54.2869 5.47232 52.5453 4.65108C51.6958 4.25462 50.7471 4.021 49.7418 4.021C49.409 4.021 49.0834 4.05639 48.7648 4.09887C46.4568 4.4387 44.5382 5.96082 43.6533 8.02808C43.3064 8.82808 43.1152 9.71303 43.1152 10.6405C43.1152 11.1927 43.1931 11.7237 43.3135 12.2334C43.4551 12.9838 43.6887 13.6776 44.0497 14.3006C44.6374 15.313 45.4444 16.0847 46.4851 16.6298C47.5259 17.175 48.7152 17.444 50.0462 17.444C51.1506 17.444 52.1489 17.2529 53.048 16.8635C53.94 16.4741 54.6834 15.936 55.2781 15.2564C55.6391 14.8387 55.6887 14.3148 55.5259 13.8688C55.3347 13.359 54.8674 12.9484 54.2232 12.9484H53.0621C52.5453 12.9484 52.0356 13.1396 51.6887 13.5219C51.2639 13.9821 50.6692 14.2086 49.9046 14.2086C49.2037 14.2086 48.6161 13.9962 48.1347 13.5644C47.6604 13.1396 47.3843 12.5237 47.3205 11.7237C47.3205 11.7166 47.3205 11.7095 47.3347 11.7095H55.1294C55.7736 11.7095 56.3046 11.1998 56.3117 10.5555V10.5414C56.3117 9.95374 56.248 9.40861 56.1276 8.89179V8.90595ZM52.5736 9.76967H47.3843C47.3843 9.76967 47.3701 9.76259 47.3701 9.75551C47.4834 9.04754 47.7807 8.50241 48.2551 8.11303C48.7365 7.72365 49.3241 7.5325 50.025 7.5325C50.7259 7.5325 51.363 7.73781 51.8515 8.14135C52.3329 8.54489 52.5807 9.08294 52.5807 9.76259C52.5807 9.76967 52.5807 9.77675 52.5666 9.77675L52.5736 9.76967Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13255_46566"
          x1="0"
          y1="12"
          x2="20.3965"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009EB5" />
          <stop offset="0.98" stopColor="#00CC7C" />
        </linearGradient>
        <clipPath id="clip0_13255_46566">
          <rect width="56.3115" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
