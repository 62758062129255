import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';

const formatDate = date => {
  return date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
};

const StyledButton = props => {
  return (
    <Button
      {...props}
      background="white"
      border="1px solid"
      borderColor="brand.black"
      color="brand.black"
      fontWeight="400"
    />
  );
};

const DateRange = ({ column, requestState, setRequestState }) => {
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [show, setShow] = useState(false);
  const [isSelectionSingleDay, setIsSelectionSingleDay] = useState(true);

  const getButtonText = () => {
    const filterStartDate = requestState.filterQuery[`${column.apiKey}Start`];
    const filterEndDate = requestState.filterQuery[`${column.apiKey}End`];
    if (filterStartDate && filterEndDate) {
      const start = new Date(filterStartDate);
      const end = new Date(filterEndDate);
      if (filterStartDate === filterEndDate) {
        return `${formatDate(start)}`;
      }
      return `${formatDate(start)}-${formatDate(end)}`;
    }
    return 'Select date range';
  };

  const handleChange = e => {
    const start = e[column.apiKey].startDate;
    const end = e[column.apiKey].endDate;
    setStartDate(start);
    setEndDate(end);
    setIsSelectionSingleDay(start.toUTCString() === end.toUTCString());
  };

  const handleClear = () => {
    setStartDate(today);
    setEndDate(today);
    setIsSelectionSingleDay(true);
    const newFilter = {
      [`${column.apiKey}Start`]: null,
      [`${column.apiKey}End`]: null,
    };
    setRequestState({
      ...requestState,
      filterQuery: {
        ...requestState.filterQuery,
        ...newFilter,
      },
      orders: [],
      isLoading: true,
    });
    setShow(false);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: column.apiKey,
  };

  const handleSubmit = e => {
    const newFilter = {
      [`${column.apiKey}Start`]: startDate.toUTCString(),
      [`${column.apiKey}End`]: endDate.toUTCString(),
    };

    setRequestState({
      ...requestState,
      filterQuery: {
        ...requestState.filterQuery,
        ...newFilter,
      },
      orders: [],
      isLoading: true,
    });
    setShow(false);
  };

  return (
    <Box position="relative">
      <StyledButton
        onClick={e => setShow(!show)}
        isDisabled={requestState.isLoading}
      >
        {getButtonText()}
      </StyledButton>
      {show && (
        <>
          <Box
            position="absolute"
            top="100%"
            right="0"
            border="1px solid"
            borderColor="grey"
            zIndex="100"
            background="white"
            padding="5px 5px 10px"
            borderRadius="5px"
            marginTop="5px"
          >
            <Box display="block">
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleChange}
                retainEndDateOnFirstSelection={true}
              />
            </Box>
            <Flex justifyContent="space-around">
              <StyledButton onClick={handleSubmit}>
                {isSelectionSingleDay &&
                  `Show results for ${formatDate(startDate)}`}
                {!isSelectionSingleDay &&
                  `Show results between ${formatDate(
                    startDate
                  )} and ${formatDate(endDate)}`}
              </StyledButton>
              <StyledButton onClick={handleClear}>Clear</StyledButton>
            </Flex>
          </Box>
        </>
      )}
    </Box>
  );
};
export default DateRange;
