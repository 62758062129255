import { Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../components//layout/Layout';
import OrderView from '../components/orders/OrderView';
import { useAuth0 } from '@auth0/auth0-react';
import stringifyFilterQuery from './stringifyFilterQuery';
import settings from '../settings';
import { defaultFilter as defaultTypeFilter } from '../components/orders/enums/orderTypeOptions';

const loadOrdersEndpoint = settings.apiV1Orders;

const PrintRoutingContainer = () => {
  const { getAccessTokenSilently } = useAuth0();

  // I don't really like this, I think we'd be better setting up reducers or something, but it works for now
  const [requestState, setRequestState] = useState({
    isLoading: true,
    page: 1,
    sortColumn: 'completedUtcDate',
    sortOrder: 'desc',
    filterQuery: {
      orderStatus: 3,
      type: defaultTypeFilter,
      includeTestOrders: false,
      isPrintReady: true,
      pageSize: 30,
    },
    orders: [],
    isLoadingNextPage: false,
    selectedOrders: new Set(),
    refreshOrders: 0,
    error: null,
  });

  const loadOrders = useCallback(
    async token => {
      const tokenMemo = await token;

      const response = await fetch(
        `${loadOrdersEndpoint}?pageNumber=${requestState.page}&sortColumn=${
          requestState.sortColumn
        }&sortOrder=${requestState.sortOrder}${stringifyFilterQuery(
          requestState.filterQuery
        )}`,
        {
          headers: {
            Authorization: `Bearer ${tokenMemo}`,
          },
        }
      );
      return await response;
    },
    [
      requestState.page,
      requestState.sortColumn,
      requestState.sortOrder,
      requestState.filterQuery,
    ]
  );

  const token = Promise.resolve(
    useMemo(async () => {
      return await getAccessTokenSilently({
        audience: settings.audience,
      });
    }, [getAccessTokenSilently])
  );

  // Don't think the api has a method to exclude through the filters, so this is a simple workaround
  const removeDigitalFileDownloads = orders => {
    if (!orders) return [];
    return orders.filter(
      order =>
        !(
          [257, 170, 281, 252].includes(order.shippingMenuOptionId) ||
          ['Digital', 'Digital File'].includes(order.paperLaminate)
        )
    );
  };

  useEffect(() => {
    loadOrders(token)
      .then(async res => await res?.json())
      .then(res =>
        setRequestState({
          ...requestState,
          isLoading: false,
          isLoadingNextPage: false,
          orders: [
            ...requestState.orders,
            ...removeDigitalFileDownloads(res.items),
          ],
        })
      )
      .catch(e => {
        setRequestState({
          ...requestState,
          error: e.message ?? 'An error occoured while loading orders',
        });
      }); //eslint-disable-line
  }, [token, loadOrders]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Flex>
        <OrderView
          requestState={requestState}
          setRequestState={setRequestState}
        />
      </Flex>
    </Layout>
  );
};

export default PrintRoutingContainer;
