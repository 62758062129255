import { Modal, ModalOverlay } from '@chakra-ui/react';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { defaultFilter as defaultTypeFilter } from '../orders/enums/orderTypeOptions';

import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Thead,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Filters from '../filters/Filters';
import ErrorModal from '../modals/ErrorModal';
import PrintModal from '../modals/PrintModal';
import HeadingsRow from './HeadingsRow';
import OrderRows from './OrderRows';

const distanceFromTop = '112px';
const distanceFromBottom = '79px';

const OrderView = ({ requestState, setRequestState }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loadMoreHandler = () => {
    setRequestState({
      ...requestState,
      page: requestState.page + 1,
      isLoadingNextPage: true,
    });
  };
  const countSelectedOrders = () => {
    const count = requestState.selectedOrders.size;
    if (count === 0) return;
    return `(${count})`;
  };

  const handleReload = e => {
    setRequestState({
      isLoading: true,
      page: 1,
      sortColumn: 'completedUtcDate',
      sortOrder: 'desc',
      filterQuery: {
        ...requestState.filterQuery,
        orderStatus: 3,
        type: defaultTypeFilter,
        includeTestOrders: false,
        isPrintReady: true,
        pageSize: 30,
      },
      orders: [],
      isLoadingNextPage: false,
      selectedOrders: new Set(),
      refreshOrders: 0,
      error: null,
    });
  };

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Flex flexDirection="column" margin="0 10px" overflowX="scroll">
      <Box
        border="1px"
        borderColor="brand.secondary"
        marginBottom="21px"
        minH={`calc(100vh - ${distanceFromTop} - ${distanceFromBottom})`}
      >
        <PrintModal
          isOpen={isOpen}
          onClose={handleModalClose}
          selectedOrders={requestState.selectedOrders}
          onSuccessClose={handleReload}
        />

        {!!requestState.error && (
          <Modal isOpen={!!requestState.error} onClose={handleModalClose}>
            <ModalOverlay data-testid="overlay" />
            <ErrorModal error={requestState.error} onClose={handleReload} />
          </Modal>
        )}

        <Flex padding="6px" justifyContent="space-between" alignItems="center">
          <PrimaryButton
            text={'Reload Orders'}
            onClick={handleReload}
            isDisabled={requestState.isLoading}
            margin={5}
          />

          <Filters
            requestState={requestState}
            setRequestState={setRequestState}
          />
          <PrimaryButton
            text={`Send to print ${countSelectedOrders() ?? ''}`}
            onClick={onOpen}
            isDisabled={!requestState.selectedOrders.size}
            margin={5}
          />
        </Flex>
        <TableContainer
          borderTop="1px"
          borderColor="#F3F4F6"
          minHeight="inherit"
        >
          <Table>
            <Thead>
              <HeadingsRow
                requestState={requestState}
                setRequestState={setRequestState}
              />
            </Thead>
            <Tbody>
              <OrderRows
                requestState={requestState}
                setRequestState={setRequestState}
              />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex justify="center">
        <PrimaryButton
          text={'Load More'}
          onClick={loadMoreHandler}
          disabled={requestState.isLoadingNextPage}
          margin={5}
        />
      </Flex>
    </Flex>
  );
};

export default OrderView;
