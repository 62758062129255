const orderTypeOptions = {
  empty: 'All',
  0: 'Premium Design Service',
  1: 'Menu Creator',
  3: 'Point of Sale',
  6: 'PWA',
  7: 'Custom Orders',
};

const defaultFilter = '0,1,3,6,7';

export { orderTypeOptions, defaultFilter };
