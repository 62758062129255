import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Text, Th, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import FreeTextFilter from '../filters/FreeTextFilter';
import columns from './columns';
import Dropdown from '../filters/Dropdown';
import DateRange from '../filters/DateRange';

const SortIcon = ({ column, sortColumn, sortOrder }) => {
  if (column.apiKey === sortColumn) {
    if (sortOrder === 'desc') return <TriangleDownIcon />;
    if (sortOrder === 'asc') return <TriangleUpIcon />;
  }
  if (column.sortable) {
    return (
      <Box display="inline-block" marginRight="4px">
        <TriangleUpIcon display="block" />
        <TriangleDownIcon display="block" />
      </Box>
    );
  }
  return;
};

const HeadingsRow = ({ requestState, setRequestState }) => {
  const [elementWithFocus, setElementWithFocus] = useState('');

  const handleClickGenerator = column => {
    return e => {
      if (column.apiKey === requestState.sortColumn) {
        const newSortOrder = requestState.sortOrder === 'desc' ? 'asc' : 'desc';
        setRequestState({
          ...requestState,
          sortOrder: newSortOrder,
          orders: [],
          isLoading: true,
        });
        return;
      }
      if (column.sortable) {
        setRequestState({
          ...requestState,
          sortOrder: 'desc',
          sortColumn: column.apiKey,
          page: 1,
          orders: [],
          isLoading: true,
        });
      }
    };
  };

  const areAllSelected = () =>
    requestState.selectedOrders.size === requestState.orders.length;

  const handleCheckboxClick = () => {
    if (areAllSelected()) {
      setRequestState({ ...requestState, selectedOrders: new Set() });
      return;
    }
    setRequestState({
      ...requestState,
      selectedOrders: new Set(requestState.orders),
    });
  };

  return (
    <Tr height="90px">
      <Th>
        <Checkbox
          onChange={e => handleCheckboxClick()}
          isChecked={areAllSelected()}
          colorScheme="teal"
        />
      </Th>
      {columns.map(column => (
        <Th
          key={column.apiKey}
          textTransform="none"
          verticalAlign="top"
          height="90px"
        >
          <Box
            cursor={column.sortable ? 'pointer' : 'default'}
            onClick={handleClickGenerator(column)}
            height="25px"
            display="flex"
            alignItems="center"
          >
            <SortIcon
              column={column}
              sortColumn={requestState.sortColumn}
              sortOrder={requestState.sortOrder}
            />
            <Text display="inline-block">{column.readableName}</Text>
          </Box>
          {column.searchType === 'dropdown' && (
            <Dropdown
              column={column}
              requestState={requestState}
              setRequestState={setRequestState}
            />
          )}

          {column.searchType === 'dateRange' && (
            <DateRange
              column={column}
              requestState={requestState}
              setRequestState={setRequestState}
            />
          )}

          {column.searchType === 'text' && (
            <FreeTextFilter
              column={column}
              requestState={requestState}
              setRequestState={setRequestState}
              hasFocus={column.apiKey === elementWithFocus}
              setElementWithFocus={setElementWithFocus}
            />
          )}
        </Th>
      ))}
    </Tr>
  );
};

export default HeadingsRow;
