import { Box, Checkbox, Td, Tr } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import Loader from '../layout/Loader';
import columns from './columns';
import OrderId from './tableCells/OrderId';
import OrderStatus from './tableCells/OrderStatus';
import OrderType from './tableCells/OrderType';
import Coalesce from './tableCells/Coalesce';
import PrintStatus from './tableCells/PrintStatus';
import ShipmentId from './tableCells/ShipmentId';
import DateCell from './tableCells/DateCell';
import QuotedBy from './tableCells/QuotedBy';

const LoadingRow = () => {
  return (
    <Tr>
      <Td colSpan="100%" height="150px">
        <Box position="relative" width="100vw" height="150px">
          <Box
            position="absolute"
            left="50%"
            translateX="-50%"
            translateY="-50%"
          >
            <Loader />
          </Box>
        </Box>
      </Td>
    </Tr>
  );
};

const OrderRows = ({ requestState, setRequestState }) => {
  const isSelected = useCallback(
    order => requestState.selectedOrders.has(order),
    [requestState.selectedOrders]
  );

  if (requestState.isLoading) {
    return <LoadingRow />;
  }

  const handleCheckboxClick = order => {
    const newSet = new Set(requestState.selectedOrders);
    if (isSelected(order)) {
      newSet.delete(order);
    } else {
      newSet.add(order);
    }
    setRequestState({ ...requestState, selectedOrders: newSet });
  };

  const orders = requestState.orders.map(order => {
    const isErrorRow = order.printStatus === 6;
    return (
      <Tr
        key={order.id}
        backgroundColor={isErrorRow && 'rgba(239, 68, 68,0.2)'} //this is the same as brand.error but with 0.2 opacity
      >
        <Td>
          <Checkbox
            borderColor={isErrorRow && '#dececa'} // original colour doesn't stand out against red background
            onChange={e => handleCheckboxClick(order)}
            isChecked={isSelected(order)}
            colorScheme="teal"
          />
        </Td>
        {columns.map((column, i) => {
          if (column.apiKey === 'id') return <OrderId key={i} id={order.id} />;
          if (column.apiKey === 'shipmentId')
            return <ShipmentId key={i} id={order.shipmentId} />;
          if (column.apiKey === 'orderStatus')
            return <OrderStatus key={i} status={order.orderStatus} />;
          if (column.apiKey === 'quotedBy')
            return <QuotedBy key={i} printer={order.quotedBy} />;
          if (column.apiKey === 'printStatus')
            return <PrintStatus key={i} status={order.printStatus} />;
          if (column.apiKey === 'type')
            return <OrderType key={i} type={order.type} />;
          if (column.apiKey.includes('|'))
            return <Coalesce key={i} order={order} apiKey={column.apiKey} />;
          if (column.apiKey.includes('Date'))
            return <DateCell key={i} date={order[column.apiKey]} />;
          return <Td key={i}>{order[column.apiKey]}</Td>;
        })}
      </Tr>
    );
  });
  if (requestState.isLoadingNextPage) {
    orders.push(<LoadingRow key="loading" />);
  }
  return orders;
};

export default OrderRows;
