import { Button, Icon, Flex } from '@chakra-ui/react';
import { gradients } from '../../theme/gradients';

export const PrimaryButton = ({
  text,
  onClick,
  icon,
  sm = false,
  bgGradient = 'blueMartini',
  isDisabled,
  isLoading,
  loadingText,
  dataTestId,
  margin,
}) => {
  return (
    <Button
      color={'white'}
      bgGradient={isDisabled ? '' : gradients[bgGradient][0]}
      bgColor={isDisabled ? 'colors.charcoalGrey' : ''}
      borderRadius={'999px'}
      paddingLeft={sm ? '5' : '6'}
      paddingRight={sm ? (icon ? '4' : '5') : icon ? '5' : '6'}
      margin={margin}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingText={loadingText}
      _disabled={{ bg: 'charcoal.300' }}
      _focus={{ bgGradient: isDisabled ? '' : gradients['blueMartini']?.[1] }}
      _hover={{ bgGradient: isDisabled ? '' : gradients['blueMartini']?.[1] }}
      data-testid={dataTestId}
    >
      <Flex gap={3} align={'center'}>
        {text}
        {icon && <Icon as={icon} />}
      </Flex>
    </Button>
  );
};
