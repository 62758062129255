import settings from '../settings';

const postOrderToPrint = async (tokenMemo, body) => {
  const token = await tokenMemo;

  const response = await fetch(settings.apiV1SendToPrint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.ok) {
    return response.json();
  }
  const err = await response.json();
  throw new Error(err.error || "Couldn't send order to print");
};
export default postOrderToPrint;
