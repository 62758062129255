import { Td } from '@chakra-ui/react';
import LinkBadge from './LinkBadge';

const OrderId = ({ id }) => (
  <Td>
    <LinkBadge href={`https://www.menumaker.co/#/Orders/${id}/OrderSummary`}>
      {id}
    </LinkBadge>
  </Td>
);

export default OrderId;
