import settings from '../settings';

const getPrinters = async tokenMemo => {
  try {
    const token = await tokenMemo;

    const response = await fetch(settings.apiV1Printers, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.json();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
export default getPrinters;
