import { useAuth0 } from '@auth0/auth0-react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import getPrinters from '../../api/getPrinters';
import postOrderToPrint from '../../api/postOrderToPrint';
import settings from '../../settings';
import Loader from '../layout/Loader';
import ErrorModal from './ErrorModal';
import PrintersModal from './PrintersModal';
import SuccessModal from './SuccessModal';

const sortAlphabetically = (a, b) => {
  const A = a.name.toUpperCase();
  const B = b.name.toUpperCase();
  return A < B ? -1 : A > B ? 1 : 0;
};

export function isManual(printer) {
  return settings.manualPrinterIDs.includes(printer.id);
}

const PrintModal = ({ isOpen, onClose, selectedOrders, onSuccessClose }) => {
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState();
  const [error, setError] = useState();
  const [hasSent, setHasSent] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  const tokenMemo = Promise.resolve(
    useMemo(async () => {
      const token = await getAccessTokenSilently({
        audience: settings.audience,
      });

      return token;
    }, [getAccessTokenSilently])
  );

  const loadPrinters = useCallback(
    async tokenMemo => await getPrinters(tokenMemo),
    []
  );
  useEffect(() => {
    setHasSent(false);
    setError(false);
    setSelectedPrinter(null);
  }, [selectedOrders]);

  useLayoutEffect(() => {
    async function fetchPrinters() {
      const printersArray = await loadPrinters(tokenMemo);
      setPrinters(printersArray.sort(sortAlphabetically));
      setIsLoading(false);
    }
    fetchPrinters();
  }, [tokenMemo, loadPrinters]);

  const handlePrint = async selectedPrinter => {
    const body = {
      orderIds: [...selectedOrders].map(order => order.id),
      printerId: selectedPrinter.id,
      isManual: isManual(selectedPrinter),
    };
    try {
      await postOrderToPrint(tokenMemo, body);

      setHasSent(true);
    } catch (e) {
      setError(e.message);
    }
  };
  const handleClose = () => {
    setError(null);
    setHasSent(false);
    //setPrinters([]);
    onClose();
  };

  const handleSuccessClose = () => {
    setError(null);
    setHasSent(false);
    onSuccessClose();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay data-testid="overlay" />
      {isLoading && (
        <ModalContent>
          <Loader />
        </ModalContent>
      )}
      {hasSent && !error && (
        <SuccessModal
          selectedOrders={selectedOrders}
          selectedPrinter={selectedPrinter}
          onClose={handleSuccessClose}
        />
      )}
      {!!error && <ErrorModal error={error} onClose={handleSuccessClose} />}
      {!error && !hasSent && (
        <PrintersModal
          setSelectedPrinter={setSelectedPrinter}
          selectedPrinter={selectedPrinter}
          printers={printers}
          onClose={handleClose}
          handlePrint={handlePrint}
        />
      )}
    </Modal>
  );
};
export default PrintModal;
