import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { isManual } from './PrintModal';
import { useState } from 'react';

const PrintersModal = ({
  setSelectedPrinter,
  selectedPrinter,
  printers,
  onClose,
  handlePrint,
}) => {
  const [isSendingToPrinter, setIsSendingToPrinter] = useState(false);

  const handleSendtoPrinterPrint = async () => {
    setIsSendingToPrinter(true);
    await handlePrint(printers.find(p => p.name === selectedPrinter));
    setIsSendingToPrinter(false);
  };
  return (
    <ModalContent>
      <ModalHeader fontSize="16px" lineHeight="24px">
        Select Printer
      </ModalHeader>
      <ModalCloseButton data-testid="close" />
      <ModalBody>
        <RadioGroup onChange={setSelectedPrinter} value={selectedPrinter}>
          <Stack direction="column">
            {printers &&
              printers.map(printer => (
                <Radio
                  key={printer.id}
                  value={printer.name}
                  data-testid={printer.name}
                >
                  {printer.name} {isManual(printer) ? '(manual)' : ''}
                </Radio>
              ))}
          </Stack>
        </RadioGroup>
      </ModalBody>
      <ModalFooter justifyContent="left">
        <Button mr={3} onClick={onClose} data-testid="cancel">
          Cancel
        </Button>
        <Button
          backgroundColor="brand.primary"
          color="brand.white"
          isDisabled={!selectedPrinter || isSendingToPrinter}
          onClick={handleSendtoPrinterPrint}
        >
          {isSendingToPrinter ? 'Sending to printer...' : 'Print'}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
export default PrintersModal;
