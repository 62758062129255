import {
  orderTypeOptions,
  defaultFilter,
} from '../components/orders/enums/orderTypeOptions';

const newQueryString = (key, value) => {
  return `&${key}=${value}`;
};

const getOrderTypeValue = type => {
  if (type === '') return defaultFilter;
  if (type === defaultFilter) return type;

  const value = Object.entries(orderTypeOptions).find(([key, value]) => {
    return value === type;
  })[0];

  return value;
};

const stringifyFilterQuery = filterQuery => {
  const keys = Object.keys(filterQuery);
  return keys.reduce((acc, key) => {
    if (key === 'type') {
      return acc + newQueryString(key, getOrderTypeValue(filterQuery[key]));
    }
    return acc + newQueryString(key, filterQuery[key]);
  }, '');
};

export default stringifyFilterQuery;
