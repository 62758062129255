import { Badge, Icon, Link } from '@chakra-ui/react';

const Arrow = () => (
  <Icon
    viewBox="0 0 7 7"
    width="12px"
    height="12px"
    marginLeft="4px"
    marginRight="3px"
    padding="2.5px"
  >
    <path
      d="M1.17145 0.514719L1.17145 1.51174L5.12064 1.51527L0.817897 5.81802L1.525 6.52513L5.82775 2.22238L5.82421 6.17157L6.8283 6.17157L6.8283 0.514719L1.17145 0.514719Z"
      fill="#111827"
    />
  </Icon>
);

const LinkBadge = ({ href, children }) => (
  <Link href={href} isExternal>
    <Badge padding="4px" borderRadius="10px">
      {children}
      <Arrow />
    </Badge>
  </Link>
);
export default LinkBadge;
