import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PrintRoutingContainer from './containers/PrintRoutingContainer';
import { gradients } from './theme/gradients';
import { colors } from './theme/colors';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const theme = extendTheme({
    colors,
    gradients,
  });

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        {!isAuthenticated && !isLoading ? (
          loginWithRedirect()
        ) : (
          <Routes>
            <Route path="/" element={<PrintRoutingContainer />} />
          </Routes>
        )}
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
