import { Td } from '@chakra-ui/react';

const DateCell = ({ date }) => {
  if (!date) {
    return <Td></Td>;
  }
  const dateObj = new Date(date);
  const formattedDate = dateObj.toLocaleTimeString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  });
  return <Td>{formattedDate}</Td>;
};

export default DateCell;
