import { Td } from '@chakra-ui/react';

const OrderStatusOptions = {
  0: 'Draft',
  1: 'Submitted',
  2: 'In Progress',
  3: 'Complete',
  4: 'Shipped',
  5: 'Cancelled',
  6: 'Deleted',
  7: 'Awaiting Approval',
  8: 'Rejected',
  9: 'Accepted',
  10: 'Proof Sent',
  11: 'Awaiting Quote',
  12: 'On Hold',
  13: 'Returned',
};

const OrderStatus = ({ status }) => {
  return <Td>{OrderStatusOptions[status] || 'Not Available'}</Td>;
};
export default OrderStatus;
