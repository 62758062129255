import { Text, Box, Checkbox } from '@chakra-ui/react';
import React, { useState } from 'react';

const StyledText = ({ children }) => (
  <Text fontWeight="700" fontSize="12px" lineHeight="18px">
    {children}
  </Text>
);

const Filters = ({ requestState, setRequestState }) => {
  const [checkedValues, setCheckedValues] = useState({
    isPrintReady: true,
    isCustomPrint: false,
    includeTestOrders: false,
  });
  const handleChange = e => {
    const value = e.target.value;
    const newCheckedValue = !checkedValues[value];
    setCheckedValues({ ...checkedValues, [value]: newCheckedValue });
    setRequestState({
      ...requestState,
      filterQuery: {
        ...requestState.filterQuery,
        [value]: newCheckedValue,
      },
      page: 1,
      orders: [],
      isLoading: true,
    });
  };

  return (
    <Box minW="182px" padding="0 8px">
      <Checkbox
        value={'isPrintReady'}
        isChecked={checkedValues.isPrintReady}
        onChange={handleChange}
        isDisabled={requestState.isLoading}
        padding="10px"
        colorScheme="teal"
      >
        <StyledText>Ready To Print</StyledText>
      </Checkbox>
      <Checkbox
        value={'isCustomPrint'}
        isChecked={checkedValues.isCustomPrint}
        isDisabled={requestState.isLoading}
        onChange={handleChange}
        padding="10px"
        colorScheme="teal"
      >
        <StyledText>Custom Print</StyledText>
      </Checkbox>
      <Checkbox
        value={'includeTestOrders'}
        isChecked={checkedValues.includeTestOrders}
        isDisabled={requestState.isLoading}
        onChange={handleChange}
        padding="10px"
        colorScheme="teal"
      >
        <StyledText>Include Test Orders</StyledText>
      </Checkbox>
    </Box>
  );
};

export default Filters;
