const printStatusOptions = {
  empty: 'Not Available',
  0: 'SentToPrinter',
  1: 'WithPrinter',
  2: 'InProduction',
  3: 'Printed',
  4: 'Dispatched',
  5: 'Rejected',
  6: 'Error',
  7: 'Cancelled',
};
export default printStatusOptions;
