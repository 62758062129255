import { Select } from '@chakra-ui/react';
import printStatusOptions from '../orders/enums/printStatusOptions';
import { useEffect, useState } from 'react';
import { orderTypeOptions } from '../orders/enums/orderTypeOptions';

const optionsForColumn = {
  printStatus: printStatusOptions,
  type: orderTypeOptions,
};

const Dropdown = ({ column, requestState, setRequestState }) => {
  const emptyValue = optionsForColumn[column.apiKey].empty;
  const [searchValue, setSearchValue] = useState(emptyValue);
  const [touched, setTouched] = useState(false);

  const options = Object.values(optionsForColumn[column.apiKey]);

  useEffect(() => {
    if (touched) {
      setRequestState({
        ...requestState,
        filterQuery: {
          ...requestState.filterQuery,
          [column.apiKey]: searchValue === emptyValue ? '' : searchValue,
        },
        orders: [],
        isLoading: true,
      });
    }
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      width="150px"
      borderColor="brand.black"
      isDisabled={requestState.isLoading}
      onChange={e => {
        setTouched(true);
        setSearchValue(e.target.value);
      }}
      value={searchValue}
    >
      {options.map(o => (
        <option key={o} value={o}>
          {o}
        </option>
      ))}
    </Select>
  );
};
export default Dropdown;
