import React from 'react';
import {
  Flex,
  Spacer,
  Button,
  Text,
  Box,
  Icon,
  Link,
  HStack,
} from '@chakra-ui/react';

import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../settings';
import { ChevronLeftIcon } from '@chakra-ui/icons';

import HybleLogo from '../../assets/HybleLogo';
import LogoutIcon from './LogoutIcon';
import SupportIcon from './SupportIcon';

const HeaderBar = () => {
  const { logout } = useAuth0();
  const restartProcess = () => window.location.reload(true);

  return (
    <Flex
      h="60px"
      bg="hyble.darkestTeal"
      alignItems="center"
      zIndex="10"
      px="24px"
      position={'sticky'}
      top={0}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.25)"
    >
      <Button
        onClick={restartProcess}
        background="none"
        padding="0"
        _hover={{ bgColor: 'none' }}
        _active={{ bgColor: 'none' }}
      >
        <HybleLogo />
      </Button>

      <Button variant="ghost" sx={buttonStyles} pb="2px">
        <ChevronLeftIcon color="white" mr="4px" />
        <Text
          as="a"
          color="white"
          fontSize="12px"
          fontWeight="700"
          href={settings.mrmToolkitUrl}
        >
          Back to Toolkit
        </Text>
      </Button>

      <Spacer />

      <HStack spacing={4}>
        <Link
          href={'https://support.hyble.tech/'}
          isExternal
          color={'brand.primary'}
          _hover={{ color: 'hyble.teal' }}
          rel="noreferrer"
          style={{ cursor: 'pointer' }}
        >
          <Icon w="24px" h="24px" as={SupportIcon} />
        </Link>

        <Box
          color={'brand.primary'}
          _hover={{ color: 'hyble.teal' }}
          onClick={() => logout({ returnTo: window.location.origin })}
          style={{ cursor: 'pointer' }}
        >
          <Icon as={LogoutIcon} w="24px" h="24px" />
        </Box>
      </HStack>
    </Flex>
  );
};

export default HeaderBar;

const buttonStyles = {
  color: 'white',
  fontSize: '14px',
  fontWeight: '700',
  _hover: { opacity: 0.7 },
};
