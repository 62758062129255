import { createIcon } from '@chakra-ui/react';
import React from 'react';

const LogoutIcon = createIcon({
  displayName: 'Logout',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M21.953 17.7577C22.0067 17.6951 22.0515 17.6324 22.0783 17.5608C22.1051 17.4982 22.1141 17.4356 22.1141 17.364C22.1141 17.3461 22.123 17.3192 22.123 17.3013C22.123 17.2118 22.1051 17.1224 22.0694 17.0418C22.0336 16.9613 21.9888 16.8897 21.9262 16.8271L19.0626 13.9993C18.8031 13.7398 18.3736 13.7398 18.1141 13.9993C17.8546 14.2588 17.8546 14.6884 18.1141 14.9479L19.8143 16.6302L11.5906 16.657C11.2237 16.657 10.9195 16.9613 10.9195 17.3282C10.9195 17.6951 11.2237 17.9993 11.5906 17.9993L19.8233 17.9725L18.0962 19.7174C17.8367 19.9769 17.8367 20.4065 18.0962 20.666C18.2304 20.7913 18.4004 20.8629 18.5705 20.8629C18.7405 20.8629 18.9195 20.8002 19.0447 20.666L21.9083 17.7756C21.9083 17.7756 21.9083 17.7577 21.9262 17.7577H21.953Z"
        fill="currentColor"
      />
      <path
        d="M14.6868 20.5856L5.27293 20.6661C5.04027 20.6482 4.83445 20.5766 4.66443 20.4066C4.45861 20.2008 4.34228 19.9055 4.34228 19.6012V19.5117C4.34228 16.1202 7.28635 13.3551 10.8926 13.3551C12.4855 13.3551 14.0157 13.901 15.2148 14.8943C15.5011 15.1269 15.9217 15.0912 16.1633 14.8048C16.396 14.5184 16.3602 14.0979 16.0738 13.8563C14.6331 12.6661 12.7897 12.0039 10.8926 12.0039C6.54362 12.0129 3 15.3775 3 19.5117V19.6012C3 20.2634 3.25951 20.9077 3.71588 21.3551C4.13647 21.7757 4.68233 21.9994 5.26398 21.9994H5.28188L14.6957 21.9189C15.0716 21.9189 15.3669 21.6146 15.3579 21.2388C15.3579 20.8719 15.0537 20.5766 14.6868 20.5766V20.5856Z"
        fill="currentColor"
      />
      <path
        d="M10.5973 11.557C13.2282 11.557 15.3758 9.4094 15.3758 6.77852C15.3758 4.14765 13.2282 2 10.5973 2C7.96644 2 5.81879 4.14765 5.81879 6.77852C5.81879 9.4094 7.96644 11.557 10.5973 11.557ZM10.5973 3.34228C12.4944 3.34228 14.0336 4.88143 14.0336 6.77852C14.0336 8.67561 12.4944 10.2148 10.5973 10.2148C8.70022 10.2148 7.16107 8.67561 7.16107 6.77852C7.16107 4.88143 8.70022 3.34228 10.5973 3.34228Z"
        fill="currentColor"
      />
    </>
  ),
});

export default LogoutIcon;
