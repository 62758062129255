export const gradients = {
  primaryBackground: [
    'linear(to-tr, #edd698, #a381d6)',
    'linear(to-t, #7adef5, #84e8c3)',
    'linear(to-b, #01111c, #2b5663)',
  ],
  blueMartini: [
    'linear-gradient(270.4deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
    'linear-gradient(90deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
  ],
};
